import './Compliance.css'

import { Grid, Radio, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import { red } from '@material-ui/core/colors'
import { withStyles } from '@material-ui/core/styles'
import { getAllergenAction } from 'actions/allergenActions'
import getCodeInfo from 'actions/codeActions'
import { getComplianceAction, getComplianceActionRetry } from 'actions/complianceActions'
import { IMAGES } from 'assets'
import {
  createListDtWeek,
  dataSearchResult,
  formatDateWithPadding,
  getSessionInfo,
  getWeekNumberOfDate,
  getWeekOfMonth,
  handleDownload2,
} from 'commons/utilities'
import { MessageErrorDialog, ProgressDialog } from 'components/commons'
import { Image } from 'components/commons'
import Footer from 'components/commons/Footer/Footer'
import ToolBar from 'components/commons/ToolBar/ToolBar'
import {
  GET_ALLERGEN_LIST_FAILED,
  GET_ALLERGEN_LIST_REQUEST,
  GET_ALLERGEN_LIST_SUCCESS,
} from 'constants/actionTypes/allergenActionTypes'
import {
  GET_COMPLIANCE_LIST_FAILED,
  GET_COMPLIANCE_LIST_REQUEST,
  GET_COMPLIANCE_LIST_SUCCESS,
  GET_COMPLIANCE_RETRY_LIST_FAILED,
  GET_COMPLIANCE_RETRY_LIST_REQUEST,
  GET_COMPLIANCE_RETRY_LIST_SUCCESS,
} from 'constants/actionTypes/complianceActionTypes'
import { CD_KEY, TOOLBAR_TYPE } from 'constants/constant'
import { ALLERGEN_CLASSFICATION_CODE, FLAG_BOOLEAN, KONTAMI_CLASSFICATION_CODE } from 'constants/constant'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

// useLocation
import styles from '../../components/commons/Table/TableCommonStyle'

function Compliance() {
  const location = useLocation()
  const dispatch = useDispatch()
  const userInfo = useSelector((state) => {
    if (state.userInfo && state.userInfo.userInfo && state.userInfo.userInfo.data) return state.userInfo.userInfo.data
    return null
  })

  const sysInfo = useSelector((state) => {
    if (state.systemInfo && state.systemInfo.system) return state.systemInfo.system
    return {}
  })

  const history = useHistory()
  const DT_TODAY = sysInfo?.DT_TODAY
    ? formatDateWithPadding(sysInfo?.DT_TODAY, 'yyyy-MM-dd')
    : formatDateWithPadding(new Date(), 'yyyy-MM-dd')
  const [searchInput, setSearchInput] = useState('')
  const [listData, setListData] = useState([])
  const [filterData, setFilterData] = useState([])
  const [messageError, setMessageError] = useState()
  const [searchData, setSearchData] = useState([])
  const [uniqueSupplierList, setUniqueSupplierList] = useState([])
  const [listAllergen, setListAllergen] = useState([])
  const listAllergenReducer = useSelector((state) => state.listAllergen || {})
  const [dateFilter, setDateFilter] = useState(getWeekNumberOfDate(DT_TODAY, true))
  const codeInfo = useSelector((state) => state.codeInfo.code || {})
  const [approvalListItems, setApprovalListItems] = useState([])
  const [approvalItem, setApprovalItem] = useState(FLAG_BOOLEAN.FALSE)

  const [loading, setLoading] = useState(false)

  const { t, i18n } = useTranslation()
  const classes = styles()

  const RedRadio = withStyles({
    root: {
      color: red[400],
      '&$checked': {
        color: red[600],
      },
    },
    checked: {},
  })((props) => <Radio color="default" {...props} />)

  const APPROVE_ALL_OPTION = '2'
  const dtWeekRef = useRef()
  const week = getWeekOfMonth(DT_TODAY)
  const temp1 = new Date(DT_TODAY).getUTCMonth() + 1
  const temp2 = new Date(DT_TODAY).getUTCFullYear()

  const currentMonth = String(temp1).length < 2 ? `0${temp1}` : `${temp1}`
  const currentYear = String(temp2)

  const currentDtWeek = currentYear + currentMonth + week

  const [filterItemDtWeek, setFilterItemDtWeek] = useState('0')

  const [listDtWeek, setListDtWeek] = useState([])

  const [listDtWeekOption, setListDtWeekOption] = useState([])

  const getDtWeekLabel = () => {
    const listDtWeekFiltered = listDtWeekOption.filter((dtWeek) => dtWeek.value === String(filterItemDtWeek))
    const dtWeekLabel = listDtWeekFiltered[0]?.label
    return dtWeekLabel
  }

  const updateDtWeekRef = async () => {
    dtWeekRef.current = await getDtWeekLabel()
  }

  const getDtWeekValue = (dtWeek, listDtWeekOpt) => {
    const dtWeekValue = listDtWeekOpt.filter((el) => {
      return String(el.label) === String(dtWeek)
    })
    return dtWeekValue[0]?.value
  }

  useEffect(() => {
    updateDtWeekRef()
  }, [filterItemDtWeek])

  const filteredFields = ['CD_SYO', 'NM_SYO', 'LIST_ALLERGEN', 'LIST_KONTAMI']

  const listComplianceReducer = useSelector((state) => state.listCompliance || {})

  const listComplianceRetryReducer = useSelector((state) => state.listComplianceRetry || {})

  const listToolBarComponent = [
    { type: TOOLBAR_TYPE.FILTER_LOGO, commonStyle: 'filterWeekContainer', componentStyle: '' },
    { type: TOOLBAR_TYPE.WEEK_CALENDAR, commonStyle: 'complianceCalendarFilter', componentStyle: 'calendar-item' },
    { type: TOOLBAR_TYPE.SEARCH, commonStyle: 'searchContainer', componentStyle: 'search-item' },
    { type: TOOLBAR_TYPE.APPROVAL_SELECT, commonStyle: 'approvalContainer', componentStyle: 'approval-select' },
    { type: TOOLBAR_TYPE.CSV, commonStyle: '', componentStyle: '' },
  ]

  useEffect(() => {
    getComplianceCheck()
    window.history.replaceState({}, document.title)
  }, [location])

  const getComplianceCheck = React.useCallback(async () => {
    const { approval = '', searchText = '', dtWeek, listDtWeek } = location?.state || {}
    //const { approval = '', searchText = '', dtWeek, listDtWeek } = location?.state || {}
    if (listDtWeek) {
      setListDtWeek(listDtWeek)
      setListDtWeekOption(createListDtWeek(listDtWeek, currentDtWeek))
    }
    if (dtWeek) {
      const dtWeekvalue = getDtWeekValue(dtWeek, createListDtWeek(listDtWeek, currentDtWeek))
      setFilterItemDtWeek(dtWeekvalue)
      dispatch(getComplianceActionRetry({ week: dtWeek, cd_cust: userInfo?.CD_CUST }))
    } else {
      dispatch(getComplianceAction({ cd_cust: userInfo?.CD_CUST }))
    }
    if (approval) {
      setApprovalItem(approval)
    }
    if (searchText) {
      setSearchInput(searchText)
    }
  }, [location])

  useEffect(() => {
    updateDtWeekRef()
    dispatch(getCodeInfo())
    dispatch(getAllergenAction())
    dispatch(getComplianceActionRetry({ week: dtWeekRef.current, cd_cust: userInfo?.CD_CUST }))
  }, [dispatch])

  useEffect(() => {
    if (Object.keys(codeInfo).length) {
      let codeFilter = codeInfo.filter((el) => el.CD_KEY === CD_KEY.APRVL)

      const approvalItems = []

      if (!codeFilter || !codeFilter.length) {
        setApprovalListItems(approvalItems)
        return
      }

      Object.values(codeFilter[0]).forEach((element, index) => {
        if (typeof element === 'object') {
          approvalItems.push({
            value: element.CD_VALUE || index,
            label: element.NM_VALUE || index,
          })
        }
      })

      setApprovalListItems(approvalItems)
    }
  }, [codeInfo])

  const isSupplierGroupEmpty = (code) => {
    return filterData.some(
      (data) => data.CD_HAT === code && (approvalItem === APPROVE_ALL_OPTION || data.KB_APPR === approvalItem)
    )
  }

  useEffect(() => {
    if (listData.length >= 0 || filterData.length >= 0) {
      // Find all unquie supplier code from data response
      let supplierListArr = []
      let uniqueSupplierCode = [...new Set(filterData.map((item) => item.CD_HAT))].filter((code) =>
        isSupplierGroupEmpty(code)
      )
      uniqueSupplierCode.forEach((supplierCode) => {
        supplierListArr.push(filterData.find((data) => data.CD_HAT === supplierCode))
      })

      setUniqueSupplierList(supplierListArr)
    }
  }, [listData, filterData, approvalItem])

  useEffect(() => {
    if (listData.length >= 0 && searchInput.length === 0) {
      setFilterData(listData)
    }
  }, [listData, searchInput, searchData])

  useEffect(() => {
    //if (listComplianceRetryReducer.listComplianceRetry) {
    if (listComplianceReducer?.listCompliance?.listDtWeek) {
      let listDTWeek = listComplianceReducer.listCompliance.listDtWeek
      //listDTWeek = listDTWeek.filter((week) =>
      //  listComplianceReducer?.listCompliance?.scanData.some(
      //    (data) => data.DT_WEEK === week && data.CD_CUST === userInfo?.CD_CUST
      //  )
      //)
      setListDtWeek(listDTWeek)
      setListDtWeekOption(createListDtWeek(listDTWeek, currentDtWeek))
      updateDtWeekRef()
    }
    if (listComplianceRetryReducer?.listComplianceRetry) {
      let listdataReducer = listComplianceRetryReducer?.listComplianceRetry?.data
      let dataResponse = []
      if (listdataReducer) {
        dataResponse = listdataReducer
          .map((data) => ({
            ...data,
            KB_CNFM: data.hasOwnProperty('KB_CNFM') ? data.KB_CNFM : FLAG_BOOLEAN.FALSE,
            KB_APPR: data.hasOwnProperty('KB_APPR') ? data.KB_APPR : FLAG_BOOLEAN.FALSE,
            KB_CMPL: data.hasOwnProperty('KB_CMPL') ? data.KB_CMPL : FLAG_BOOLEAN.FALSE,
            KB_KINFO: data.hasOwnProperty('KB_KINFO') ? data.KB_KINFO : FLAG_BOOLEAN.FALSE,
            LIST_ALLERGEN: renderAllrgenData(data),
            LIST_KONTAMI: renderKontamiData(data),
          }))
          .filter((el) => (userInfo.hasOwnProperty('CD_CUST') ? el.CD_CUST === userInfo?.CD_CUST : true))
        setListData(dataResponse)
      }

      if (searchInput !== '') {
        setFilterData(dataSearchResult(dataResponse, filteredFields, searchInput))
      }
    }
    //}

    if (listAllergenReducer.listAllergen.length >= 0) {
      setListAllergen([...listAllergenReducer.listAllergen])
    }

    setLoading(listComplianceRetryReducer.loading || listComplianceReducer.loading || listAllergenReducer.loading)
    switch (listComplianceReducer.type) {
      case GET_COMPLIANCE_LIST_REQUEST:
        break
      case GET_COMPLIANCE_LIST_FAILED:
        if (!getSessionInfo()) {
          setMessageError(listComplianceReducer.message)
        }
        break
      case GET_COMPLIANCE_LIST_SUCCESS:
        break
      default:
        return
    }

    switch (listComplianceRetryReducer.type) {
      case GET_COMPLIANCE_RETRY_LIST_REQUEST:
        break
      case GET_COMPLIANCE_RETRY_LIST_FAILED:
        if (!getSessionInfo()) {
          listComplianceRetryReducer.message && setMessageError(listComplianceRetryReducer.message)
        }
        break
      case GET_COMPLIANCE_RETRY_LIST_SUCCESS:
        break
      default:
        return
    }

    switch (listAllergenReducer.type) {
      case GET_ALLERGEN_LIST_REQUEST:
        break
      case GET_ALLERGEN_LIST_FAILED:
        if (!getSessionInfo()) {
          setMessageError(listAllergenReducer.message)
        }
        break
      case GET_ALLERGEN_LIST_SUCCESS:
        break
      default:
        return
    }
  }, [listComplianceReducer, listComplianceRetryReducer, listAllergenReducer])

  const handleSearchInput = (event) => {
    event.preventDefault()
    setFilterData(dataSearchResult(listData, filteredFields, searchInput))
  }

  useEffect(() => {
    updateDtWeekRef()
  }, [filterItemDtWeek])

  const handleFilterDate = async (e) => {
    e.preventDefault()
    await updateDtWeekRef()
    try {
      await dispatch(getComplianceActionRetry({ week: dtWeekRef.current, cd_cust: userInfo?.CD_CUST }))
      console.log('Data fetched successfully.')
    } catch (error) {
      console.error('Failed to fetch data:', error)
      // エラーハンドリングをここに追加
    }
  }
  const handleUpdateApproval = (value) => {
    setApprovalItem(value)
  }

  let setDownloadParams = {
    screenName: 'compliance-customer',
    searchText: searchInput,
    week: getDtWeekLabel(),
    approval: approvalItem,
    cdCust: userInfo?.CD_CUST || '',
  }

  const handleDowloadCsv = () => {
    handleDownload2(setDownloadParams, i18n)
  }

  const toolbar = (
    <ToolBar
      listToolBarComponent={listToolBarComponent}
      selectedDate={dateFilter}
      filterLabel={t('compliance.lblWeekFilter')}
      setSelectedDate={setDateFilter}
      handleFilterDate={handleFilterDate}
      setSearchData={setSearchData}
      setSearchInput={setSearchInput}
      searchInput={searchInput}
      handleSubmit={handleSearchInput}
      approvalItem={approvalItem}
      handleUpdateApproval={handleUpdateApproval}
      approvalListItems={approvalListItems}
      //   handleUpdateCompliance={handleUpdateCompliance}
      downloadParams={setDownloadParams}
      weekInputs={true}
      handleDowloadCsv={handleDowloadCsv}
      screen={t('compliance.lblScreenName')}
      canDownloadData={true}
      listDtWeek={listDtWeek}
      listDtWeekOption={listDtWeekOption}
      setFilterItemDtWeek={setFilterItemDtWeek}
      filterItemDtWeek={filterItemDtWeek}
    />
  )

  /**
   * Get Allergen Name List base on allergen code
   * @return {String}
   */
  const getAllergenName = (allrgenCode, kbEdit, type = '', kontami = '') => {
    if (kontami === 'kontami') {
    }
    let itemCount = 1
    let listAllergenText = ''
    let target = {}
    const allergenFoundArr = []

    const allrgenCodeEdit = Object.assign(target, allrgenCode)
    if (kbEdit === FLAG_BOOLEAN.TRUE) {
      Object.keys(allrgenCodeEdit).forEach((code) => {
        if (allrgenCodeEdit[code].AF === FLAG_BOOLEAN.FALSE) {
          delete allrgenCodeEdit[code]
        }
      })
    }
    if (kbEdit === FLAG_BOOLEAN.FALSE) {
      Object.keys(allrgenCodeEdit).forEach((code) => {
        if (allrgenCodeEdit[code].BF === FLAG_BOOLEAN.FALSE) {
          delete allrgenCodeEdit[code]
        }
      })
    }
    const listAllergenReSort = []
    Object.keys(allrgenCodeEdit).forEach((code) => {
      let allergenFound = listAllergen.find((allergen) => allergen.CD_ALRG === code)
      listAllergenReSort.push(allergenFound)
    })
    listAllergenReSort
      .sort((a, b) => {
        if (a['NU_DSP'] >= b['NU_DSP']) {
          return 1
        }
        if (a['NU_DSP'] < b['NU_DSP']) {
          return -1
        }
        return 0
      })
      .forEach((allergenFound) => {
        if (typeof allergenFound !== 'undefined') {
          listAllergenText += itemCount + ' : ' + allergenFound.NM_ALRG + ', '
          allergenFoundArr.push({
            name: allergenFound.NM_ALRG,
            code: allergenFound.CD_ALRG,
            redColor:
              allrgenCodeEdit[allergenFound.CD_ALRG].AF === FLAG_BOOLEAN.TRUE &&
              allrgenCodeEdit[allergenFound.CD_ALRG].BF === FLAG_BOOLEAN.FALSE
                ? true
                : false,
          })
        }
        itemCount++
      })

    if (type === 'html') return renderListAllergenHTml(allergenFoundArr)

    return listAllergenText.substring(0, listAllergenText.length - 2)
  }

  const renderListAllergenHTml = (arr = []) => {
    return (
      <div>
        {arr.map((data, index) => {
          return (
            <>
              <span className={data.redColor ? 'text-red' : ''}>{`${data.code} : `}</span>
              <span className={data.redColor ? 'text-red' : ''}>{`${
                index === arr.length - 1 ? data.name : data.name + ', '
              } `}</span>
            </>
          )
        })}
      </div>
    )
  }

  /**
   * Return jsx or string base on type
   * @return {String || JSX}
   */
  const getTextResult = (type = '', isTextRed = false, text = '') => {
    if (type === 'html') return <span className={`${isTextRed ? 'text-red' : ''}`}>{text}</span>
    return text
  }

  /**
   * Return Allergen Text base on allergen code
   * @return {String}
   */

  const renderAllrgenData = (data, type = '') => {
    switch (data?.KB_ARGSET?.AF) {
      case ALLERGEN_CLASSFICATION_CODE.NOT_SET:
        return getTextResult(type, data.KB_ARGSET?.AF !== data.KB_ARGSET?.BF, t('compliance.lblCodeAllergenNotSet'))

      case ALLERGEN_CLASSFICATION_CODE.HAVE_ALLERGEN:
        return getAllergenName(data.CD_ARG, data.KB_EDIT, type)

      case ALLERGEN_CLASSFICATION_CODE.NOT_APPLY:
        return getTextResult(type, data.KB_ARGSET?.AF !== data.KB_ARGSET?.BF, t('compliance.lblCodeAllergenNoApply'))

      case ALLERGEN_CLASSFICATION_CODE.NO_ALLERGEN:
        return getTextResult(type, data.KB_ARGSET?.AF !== data.KB_ARGSET?.BF, t('compliance.lblCodeNotHaveAllergen'))

      default:
        return
    }
  }

  /**
   * Return Allergen Text base on allergen code
   * @return {String}
   */
  const filterDataList = (data) => {
    let value = true
    for (let i = 0; i < data.length; i++) {
      if (data[i]['AF'] === '0' && data[i]['BF'] === '0') {
        value = true
      } else {
        value = false
        break
      }
    }
    return value
  }
  const checkListDataAsNotHave = (data, type) => {
    if (data.KB_CNTMSET?.AF === FLAG_BOOLEAN.TRUE) {
      return filterDataList(Object.values(data.CD_CNTM))
    } else {
      return false
    }
  }
  const renderKontamiData = (data, type = '') => {
    switch (data?.KB_CNTMSET?.AF) {
      case KONTAMI_CLASSFICATION_CODE.NOT_SET:
        return getTextResult(type, data.KB_CNTMSET?.AF !== data.KB_CNTMSET?.BF, t('compliance.lblCodeKontamiNotSet'))

      case KONTAMI_CLASSFICATION_CODE.HAVE_KONTAMI:
        return getAllergenName(data.CD_CNTM, data.KB_EDIT, type, 'kontami')

      case KONTAMI_CLASSFICATION_CODE.NOT_APPLY:
        return getTextResult(type, data.KB_CNTMSET?.AF !== data.KB_CNTMSET?.BF, t('compliance.lblCodeKontamiNoApply'))

      case KONTAMI_CLASSFICATION_CODE.NO_KONTAMI:
        return getTextResult(type, data.KB_CNTMSET?.AF !== data.KB_CNTMSET?.BF, t('compliance.lblCodeNotHaveKontami'))

      default:
        return
    }
  }

  const handleUpdate = (id, data) => {
    history.push({
      pathname: '/customer/form-compliance-check',
      search: `?${id ? `id=${id}&` : ''}`,
      state: {
        data: data,
        date: dateFilter,
        approval: data.KB_APPR,
        approvalItem: approvalItem,
        searchText: searchInput,
        dtWeek: dtWeekRef.current,
        listDtWeek: listDtWeek,
        // year: filterItemYear,
        // month: filterItemMonth,
        // week: weekValue,
      },
    })
  }

  return (
    <div className="compliance__wrapper">
      {messageError && <MessageErrorDialog message={messageError} showMessageErrorDialog={setMessageError} />}

      {loading ? (
        <ProgressDialog label={t('portal.loading')} />
      ) : (
        <div className="compliance__container container-common">
          <div className="table-container">
            <Grid item md={12}>
              <TableContainer className="table-compliance-padding">
                <div className="table-scroll">
                  {/* Toolbar */}
                  <div className={`${uniqueSupplierList.length > 0 ? 'compliance-content-scroll' : ''}`}>
                    {toolbar}
                    {uniqueSupplierList
                      .sort((a, b) => a.CD_HAT.localeCompare(b.CD_HAT))
                      .map((el, index) => (
                        <div className="compliance-content" key={index}>
                          {/* ======================== HEADER START  ======================== */}
                          <div className="supplier-container">
                            <div className="supplier-header supplier-code supplier-groupTitle-size">
                              <span className="supplier-groupTitle">{t('compliance.lblCustCode')}</span>
                              <span>{el.CD_CUST}：</span>
                              <span>{el.NM_CUST}</span>
                            </div>
                            <div className="supplier-header supplier-name supplier-groupTitle-size">
                              <span className="supplier-groupTitle">{t('compliance.lblSupplierCode')}</span>
                              <span>{el.CD_HAT}：</span>
                              <span>{el.NM_HAT}</span>
                            </div>
                          </div>
                          {/* ======================== HEADER END  ========================*/}
                          {/* ======================== LINE START ======================== */}

                          <div style={{ width: '100%' }}>
                            <hr style={{ width: '100%', border: '2px solid rgb(85, 130, 50)' }} />
                          </div>
                          {/* ======================== LINE END ======================== */}

                          {/* ======================== TABLE START  ========================*/}

                          <Table aria-label="common table" className={classes.fullWidthTable}>
                            <colgroup className="header-col">
                              <col style={{ width: '5%' }} className="col-1-small-screen" />
                              <col style={{ width: '8%' }} className="col-2-small-screen" />
                              <col className="col-3-small-screen" />
                              <col style={{ width: '74px' }} className="col-4-small-screen" />
                              <col style={{ width: '108px' }} className="col-5-small-screen" />
                              <col style={{ width: '84px' }} className="col-6-small-screen" />
                              <col style={{ width: '105px' }} className="col-7-small-screen" />
                            </colgroup>
                            {/* ======================== TABLE HEAD START  ========================*/}
                            <TableHead className={classes.tableHead}>
                              <TableRow className="header-height">
                                <TableCell></TableCell>
                                <TableCell className={`${classes.titleHeadCompliance} ${classes.paddinglr0}`}>
                                  {t('compliance.lblProductCode')}
                                </TableCell>
                                <TableCell className={`${classes.titleHeadCompliance} ${classes.paddingHorizontal20}`}>
                                  {t('compliance.lblProductName')}
                                </TableCell>
                                <TableCell
                                  className={`${classes.titleHeadCompliance} ${classes.paddinglr0} ${classes.textCenter} ${classes.paddingHorizontal20}`}
                                >
                                  {sysInfo?.MP_COMP?.KB_SYOHYO === FLAG_BOOLEAN.TRUE
                                    ? t('compliance.lblTradeMark')
                                    : ''}
                                </TableCell>
                                <TableCell
                                  className={`${classes.titleHeadCompliance} ${classes.paddinglr0} ${classes.textCenter} ${classes.paddingHorizontal20}  `}
                                >
                                  {sysInfo?.MP_COMP?.KB_KANNAI === FLAG_BOOLEAN.TRUE
                                    ? t('compliance.lblPlanConfirm')
                                    : ''}
                                </TableCell>
                                <TableCell
                                  className={`${classes.titleHeadCompliance} ${classes.paddinglr0} ${classes.textCenter}  ${classes.paddingHorizontal20}   `}
                                >
                                  {t('compliance.lblApprovalDate')}
                                </TableCell>
                                <TableCell
                                  className={`${classes.titleHeadCompliance}  ${classes.paddinglr0}  ${classes.textCenter} ${classes.paddingHorizontal20}   `}
                                >
                                  {t('compliance.lblConfirmDate')}
                                </TableCell>
                              </TableRow>
                              <TableRow className="header-height">
                                <TableCell></TableCell>
                                <TableCell className={`${classes.titleHeadCompliance} ${classes.paddinglr0}`}>
                                  {t('compliance.lblAllergen')}
                                </TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                              </TableRow>
                              {sysInfo?.MP_COMP?.KB_CONTAMI === FLAG_BOOLEAN.TRUE ? (
                                <TableRow className="header-height">
                                  <TableCell></TableCell>
                                  <TableCell className={`${classes.titleHeadCompliance} ${classes.paddinglr0}`}>
                                    {t('compliance.lblContami')}
                                  </TableCell>
                                  <TableCell></TableCell>
                                  <TableCell></TableCell>
                                  <TableCell></TableCell>
                                  <TableCell></TableCell>
                                  <TableCell></TableCell>
                                </TableRow>
                              ) : (
                                ''
                              )}
                            </TableHead>
                            {/* ======================== TABLE HEAD END  ========================*/}
                            {/* ======================== TABLE BODY START ========================== */}
                            {filterData
                              .filter((arr) => arr.CD_HAT === el.CD_HAT)
                              .sort((a, b) => a.CD_SYO.localeCompare(b.CD_SYO))
                              .filter((data) => approvalItem === APPROVE_ALL_OPTION || data.KB_APPR === approvalItem)
                              .map((renderData, renderDataIndex) => (
                                <TableBody className="compliance-record" key={renderDataIndex}>
                                  <>
                                    <TableRow className={`${renderDataIndex % 2 === 0 ? '' : 'background-blue'}`}>
                                      <TableCell rowSpan={sysInfo?.MP_COMP?.KB_CONTAMI === FLAG_BOOLEAN.TRUE ? 3 : 2}>
                                        <div className="compliance-cell compliance-update">
                                          {renderData.KB_CNFM === FLAG_BOOLEAN.TRUE ? (
                                            ''
                                          ) : (
                                            <Image
                                              src={IMAGES.ICON.EDIT_ICON_TABLE_HQUSER}
                                              onClick={() => handleUpdate(renderDataIndex, renderData)}
                                              className={classes.iconAction}
                                            />
                                          )}
                                        </div>
                                      </TableCell>
                                      <TableCell className={classes.height35}>
                                        <div className="compliance-cell  compliance-cell-row-1 compliance-cell-border alignItemEnd paddingb-2 paddingb-2">
                                          {renderData.CD_SYO}
                                        </div>
                                      </TableCell>
                                      <TableCell className={classes.height35}>
                                        <div className="compliance-cell  compliance-cell-row-1 compliance-cell-border  alignItemEnd paddingb-2 paddingl-20">
                                          {renderData.NM_SYO}
                                        </div>
                                      </TableCell>
                                      <TableCell className={classes.height35}>
                                        <div className="compliance-cell compliance-cell-row-1 compliance-cell-border paddingb-5 radio-checkbox flex">
                                          {sysInfo?.MP_COMP?.KB_SYOHYO === FLAG_BOOLEAN.TRUE &&
                                          renderData.KB_CMPL === FLAG_BOOLEAN.TRUE ? (
                                            renderData.KB_EDIT === FLAG_BOOLEAN.TRUE ? (
                                              <RedRadio />
                                            ) : (
                                              <Radio
                                                checked={false}
                                                value="a"
                                                name="radio-button-demo"
                                                inputProps={{ 'aria-label': 'A' }}
                                                size="small"
                                              />
                                            )
                                          ) : (
                                            ''
                                          )}
                                        </div>
                                      </TableCell>
                                      <TableCell className={classes.height35}>
                                        <div className="compliance-cell compliance-cell-row-1 compliance-cell-border paddingb-5 radio-checkbox flex right-radio">
                                          {sysInfo?.MP_COMP?.KB_KANNAI === FLAG_BOOLEAN.TRUE &&
                                          renderData.KB_KINFO === FLAG_BOOLEAN.TRUE ? (
                                            renderData.KB_EDIT === FLAG_BOOLEAN.TRUE ? (
                                              <RedRadio />
                                            ) : (
                                              <Radio
                                                checked={false}
                                                value="a"
                                                name="radio-button-demo"
                                                inputProps={{ 'aria-label': 'A' }}
                                                size="small"
                                              />
                                            )
                                          ) : (
                                            ''
                                          )}
                                        </div>
                                      </TableCell>
                                      <TableCell className={classes.height35}>
                                        <div
                                          className={`${
                                            renderData.KB_EDIT === FLAG_BOOLEAN.TRUE ? 'text-red' : ''
                                          }  compliance-cell compliance-cell-row-1 compliance-cell-border  paddingb-5 flex `}
                                        >
                                          {renderData.KB_APPR === FLAG_BOOLEAN.TRUE ? (
                                            formatDateWithPadding(renderData?.DT_APPR, 'yyyy-MM-dd')
                                          ) : (
                                            <div style={{ height: '16px' }}></div>
                                          )}
                                        </div>
                                      </TableCell>
                                      <TableCell className={classes.height35}>
                                        <div className="compliance-cell  compliance-cell-row-1  compliance-cell-border  paddingb-5 flex">
                                          {formatDateWithPadding(renderData?.DT_CNFM, 'yyyy-MM-dd') || (
                                            <div style={{ height: '16px' }}></div>
                                          )}{' '}
                                        </div>
                                      </TableCell>
                                    </TableRow>
                                    <TableRow className={`${renderDataIndex % 2 === 0 ? '' : 'background-blue'}`}>
                                      <TableCell colSpan={6}>
                                        <div
                                          className={`${
                                            sysInfo?.MP_COMP?.KB_CONTAMI === FLAG_BOOLEAN.TRUE
                                              ? 'compliance-cell compliance-cell-border'
                                              : 'compliance-cell'
                                          } `}
                                        >
                                          {}
                                          {renderAllrgenData(renderData, 'html')}
                                        </div>
                                      </TableCell>
                                    </TableRow>

                                    {sysInfo?.MP_COMP?.KB_CONTAMI === FLAG_BOOLEAN.TRUE ? (
                                      <TableRow className={`${renderDataIndex % 2 === 0 ? '' : 'background-blue'}`}>
                                        <TableCell colSpan={6}>
                                          <div className={`${'compliance-cell'} `}>
                                            {checkListDataAsNotHave(renderData, 'kontami') === true
                                              ? '未設定/対象外/コンタミなし.'
                                              : ''}
                                            {renderKontamiData(renderData, 'html')}
                                          </div>
                                        </TableCell>
                                      </TableRow>
                                    ) : (
                                      ''
                                    )}
                                  </>
                                </TableBody>
                              ))}
                          </Table>

                          {/* ======================== TABLE BODY END ========================== */}

                          {/* ======================== TABLE END  ========================*/}
                        </div>
                      ))}{' '}
                  </div>
                  <Footer />
                  <div style={{ width: '100%', height: '50px' }}></div>
                </div>
              </TableContainer>
            </Grid>
          </div>
        </div>
      )}
    </div>
  )
}

export default Compliance
