const Config = {
  S3_DATA_BUCKET: 'cpspr-datatransfer-dev.data',
  AMPLIFY: {
    Auth: {
      userPoolId: process.env.REACT_APP_COGNITO_ADMIN_POOL_ID,
      region: process.env.REACT_APP_REGION,
      userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
      storage: sessionStorage,
    },
  },
}

export default Config
