/* --- User API Path --- */
export const API_GET_USER_DETAIL = '/get-user-detail'

/* --- change-password --- */
export const API_CHANGE_PASSWORD = '/change-password'

/* --- forgot-password --- */
export const API_FORGOT_PASSWORD = '/forgot-password'

/* --- Portal API Path --- */
export const API_GET_PORTALS_DETAIL = '/get-portals'

export const API_CREATE_PORTAL = '/create-portal'

export const API_UPDATE_PORTAL = '/update-portal'

export const API_DELETE_PORTAL = '/delete-portal'

/* --- System Info API Path --- */
export const API_GET_SYSTEM_INFO = 'get-sys-setting'

/* --- Code Info API Path --- */
export const API_GET_CODE_INFO = '/get-code'

/* --- upload file --- */
export const API_SIGN_S3 = '/upload-s3'

/* --- download CSV --- */
export const API_DOWNLOAD_CSV = '/download-file-csv'

/* --- check file existed in s3 --- */
export const API_CHECK_EXISTED_FILE = '/s3-check-existed-file'

/* --- data-download --- */
export const API_GET_DATA_DOWNLOAD = '/get-data-download'
export const API_UPDATE_DATA_DOWNLOAD = 'update-data-download'

/* --- allergen --- */
export const API_GET_ALLERGEN = '/get-allergens'
export const API_CREATE_ALLERGEN = '/create-allergen'
export const API_UPDATE_ALLERGEN = '/update-allergen'
export const API_DELETE_ALLERGEN = '/delete-allergen'

/* --- compliance-data --- */
export const API_GET_COMPLIANCE_DATA = '/get-compliance'
export const API_GET_COMPLIANCE_DATA_RETRY = '/get-compliance-retry'
export const API_UPDATE_COMPLIANCE_DATA = '/update-compliance'

/* --- delivery-data --- */
export const API_GET_DELIVERY_DATA = 'get-delivery-data'

/* --- delivery-data-detail--- */
export const API_GET_DELIVERY_DATA_DETAIL = 'get-delivery-data-detail'

/* --- my-page --- */
export const API_UPDATE_MY_PAGE = '/update-my-page'

/* --- custuser --- */
export const API_GET_CUSTOMER_NAME = '/get-customer-name'
export const API_GET_CUST_USER = '/get-cust-user'

/* --- generate-presigned-url-s3--- */
export const API_GENERATE_PRESIGNED_URL_S3 = '/generate-presigned-url'

/* --- get-file-from-s3--- */
export const API_GET_FILE_FROM_S3 = '/download-file'
