import './DataDownload.css'

import { getDataDownloadAction, updateDataDownloadAction } from 'actions/dataDownloadActions'
import { formatCurrentDateJPWithPadding, formatDateWithPadding, getSessionInfo } from 'commons/utilities'
import { MessageErrorDialog, ModalConfirm, ProgressDialog } from 'components/commons'
import ToolBar from 'components/commons/ToolBar/ToolBar'
import {
  GET_DATA_DOWNLOAD_LIST_FAILED,
  GET_DATA_DOWNLOAD_LIST_REQUEST,
  GET_DATA_DOWNLOAD_LIST_SUCCESS,
} from 'constants/actionTypes/dataDownloadActionTypes'
import { FLAG_BOOLEAN, TOOLBAR_TYPE } from 'constants/constant'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import TableDataDownload from './TableDataDownload/TableDataDownload'

function DataDownload() {
  const [listCheckboxData, setListCheckboxData] = useState([])
  const sysInfo = useSelector((state) => {
    if (state.systemInfo && state.systemInfo.system) return state.systemInfo.system
    return {}
  })
  const DT_TODAY = sysInfo?.DT_TODAY
    ? formatDateWithPadding(sysInfo?.DT_TODAY, 'yyyy-MM-dd')
    : formatDateWithPadding(new Date(), 'yyyy-MM-dd')
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const location = useLocation()
  const [listData, setListData] = useState([])
  const [dateFilter, setDateFilter] = useState(DT_TODAY)
  const [endDateFilter, setEndDateFilter] = useState(DT_TODAY)
  const [loading, setLoading] = useState(false)
  const [messageError, setMessageError] = useState()
  const [recordDelete, setRecordDelete] = useState()
  const listDataDownloadReducer = useSelector((state) => state.listDataDownload || {})
  const [isValidDate, setIsValidDate] = useState(true)
  const [isInDateRange, setIsInDateRange] = useState(true)
  const [listFilterItem, setListFilterItem] = useState([{}])
  const [filterItem, setFilterItem] = useState(FLAG_BOOLEAN.FALSE)
  const [undownloaded, setUndownloaded] = useState(FLAG_BOOLEAN.FALSE)
  const [isDateSelected, setIsDateSelected] = useState(false)
  const [listRawData, setListRawData] = useState([])
  const [listCacheData, setListCacheData] = useState([])
  const [selectAll, setSelectAll] = useState(true)
  const [listChoosenFolderFile, setListChoosenFolderFile] = useState([])
  const [dayCountFilter, setDayCountSelect] = useState(1)

  const [selectAllInTable, setSelectAllInTable] = useState(false)

  const [downloadErrorMessage, setDownloadErrorMessage] = useState('')
  const [previewErrorMessage, setPreviewErrorMessage] = useState('')
  const [currentDownloadedFile, setCurrentDownloadedFile] = useState({})
  const [notExistFileInS3Error, setNotExistFileInS3Error] = useState('')

  const userInfo = useSelector((state) => {
    if (state.userInfo && state.userInfo.userInfo && state.userInfo.userInfo.data) return state.userInfo.userInfo.data
    return null
  })

  const handleFilterDate = React.useCallback(
    async (dateFilter) => {
      setIsDateSelected(true)
      var dayWork = getDayCount(dateFilter, endDateFilter)
      if (isValidDate && isInDateRange) {
        if (filterItem === '0') {
          dispatch(
            getDataDownloadAction({
              date: dateFilter,
              cdCust: userInfo?.CD_CUST,
              dayCount: dayWork,
              // undownloaded: undownloaded,
            })
          )
        } else {
          dispatch(
            getDataDownloadAction({
              date: dateFilter,
              cdCust: userInfo?.CD_CUST,
              dayCount: dayWork,
              // folderName: !!listFilterItem[filterItem] && listFilterItem[filterItem].label,
              // undownloaded: undownloaded,
            })
          )
        }
      }
      setDayCountSelect(dayWork)
    },

    [dispatch, filterItem, endDateFilter]
  )

  const handleFilterEndDate = React.useCallback(
    async (endDateFilter) => {
      setIsDateSelected(true)
      var dayWork = getDayCount(dateFilter, endDateFilter)
      if (isValidDate && isInDateRange) {
        dispatch(
          getDataDownloadAction({
            date: dateFilter,
            cdCust: userInfo?.CD_CUST,
            dayCount: dayWork,
          })
        )
      }
      setDayCountSelect(dayWork)
    },
    [dispatch, filterItem, dateFilter]
  )

  useEffect(() => {
    var dayWork = getDayCount(DT_TODAY, DT_TODAY)
    if (isValidDate && isInDateRange) {
      dispatch(
        getDataDownloadAction({
          date: dateFilter,
          cdCust: userInfo?.CD_CUST,
          dayCount: dayWork,
        })
      )
    }
  }, [])

  const getDayCount = (startDate, endDate) => {
    let fromDate = new Date(startDate)
    let toDate = new Date(endDate)
    //console.log(startDate, endDate)
    var countDays = 1
    if (fromDate > toDate) {
      return 0
      //setDateFilter(endDate)
      //fromDate = new Date(endDate)
      //setMessageError(t('toolbar.msgErrorDateSelect'))
    }
    countDays = Math.floor((toDate - fromDate) / (1000 * 60 * 60 * 24)) + 1
    //console.log(countDays)

    if (countDays > 92) {
      setMessageError(t('toolbar.msgErrorMaxSelect'))
      return 0
    }
    return countDays
  }

  useEffect(() => {
    window.addEventListener('beforeunload', reloadHandler)
    return () => {
      window.removeEventListener('beforeunload', reloadHandler)
    }
  }, [])
  const reloadHandler = (e) => {
    window.reload()
  }
  const getListFolderName = (listData = []) => {
    let listFolderNameArr = []
    for (const item of listData) {
      if (!listFolderNameArr.includes(item.NM_DISP_FLD)) {
        listFolderNameArr.push(item.NM_DISP_FLD)
      }
    }
    return listFolderNameArr
  }

  const formatData = (listRawData, listFolderName, isSelected = false) => {
    let dataFormat = []
    listFolderName.forEach((folderName) => {
      let filteredList = listRawData
        .filter((data) => data?.NM_DISP_FLD === folderName)
        .map((x) => {
          return { ...x, IS_SELECTED: String(Number(selectAll)) }
        })
      if (filteredList.length > 0) {
        dataFormat.push({
          NM_DISP_FLD: folderName,
          IS_SELECTED_ALL: String(Number(selectAll)),
          list_file: filteredList,
        })
      }
    })

    if (isSelected) {
      setListCheckboxData(dataFormat)
      setListCacheData(filterDataCheckbox(listCacheData, listCheckboxData))
      dataFormat = filterDataCheckbox(dataFormat, listCacheData)
    } else {
      dataFormat = filterDataCheckbox(dataFormat, listCheckboxData)
    }

    return dataFormat
  }

  const checkDataWithItem = (list) => {
    setListCheckboxData(list)
    if (filterItem.toString() === '0') {
      setListCacheData(list)
    }
  }

  const filterDataCheckbox = (data, list) => {
    let dataResult = []
    try {
      if (list && list.length > 0) {
        data.forEach((item1) => {
          list.forEach((item2) => {
            if (item1['NM_DISP_FLD'] === item2['NM_DISP_FLD']) {
              item1['IS_SELECTED_ALL'] = item2['IS_SELECTED_ALL']
              item1['list_file'].forEach((child1) => {
                item2['list_file'].forEach((child2) => {
                  if (child1['TM_REGIST'] === child2['TM_REGIST'] && child1['CD_BRANCH'] === child2['CD_BRANCH']) {
                    child1['IS_SELECTED'] = child2['IS_SELECTED']
                  }
                })
              })
            }
          })
          dataResult.push(item1)
        })
      } else {
        dataResult = data
      }
    } catch {
      dataResult = data
    }
    return dataResult
  }

  useEffect(() => {
    setListCheckboxData([])
    setListCacheData([])
    if (listDataDownloadReducer?.listDataDownload) {
      const listData = listDataDownloadReducer?.listDataDownload
      const listFolderName = getListFolderName(listData)
      let filteredData = listData
      if (Number(undownloaded) === 1) {
        filteredData = listData?.filter((data) => {
          return Number(data?.KB_DL) !== Number(undownloaded)
        })
      }
      let folderName = !!listFilterItem[filterItem] && filterItem !== '0' ? listFilterItem[filterItem].label : undefined
      const checkFolderNameExists = listFolderName.find((folder) => folder === folderName) !== undefined ? true : false
      if (folderName && checkFolderNameExists) {
        filteredData = filteredData.filter((data) => {
          return data.NM_DISP_FLD === folderName
        })
      } else {
        setFilterItem(listFilterItem[0].value)
      }
      listFolderName.sort()
      const dataFormat = formatData(filteredData, listFolderName)

      setListData(dataFormat)

      setListRawData(listData)
      // setListFolderName(listFolderName?.sort((a, b) => a.localeCompare(b, 'jp', { ignorePunctuation: true })))
      if (listFolderName) {
        let array = []
        array.push({
          value: '0',
          label: t('dataDownload.allFolder'),
        })
        for (let i = 0; i < listFolderName?.length; i++) {
          let j = i + 1
          let filterItem = {
            value: j.toString(),
            label: listFolderName[i],
          }
          array.push(filterItem)
        }
        setListFilterItem(array)
        let indexCurrentFolderName = array.find((el) => el.label === folderName)?.value
        if (indexCurrentFolderName) {
          setFilterItem(indexCurrentFolderName)
        }
        // setFilterItem(listFilterItem[0].value)
      }
    }

    setLoading(listDataDownloadReducer.loading)
    switch (listDataDownloadReducer.type) {
      case GET_DATA_DOWNLOAD_LIST_REQUEST:
        break
      case GET_DATA_DOWNLOAD_LIST_FAILED:
        if (!getSessionInfo()) {
          setMessageError(listDataDownloadReducer.message)
        }
        break
      case GET_DATA_DOWNLOAD_LIST_SUCCESS:
        break
      default:
        return
    }
  }, [listDataDownloadReducer])

  const handleSelectFolderName = (filterItem1) => {
    const folderName =
      !!listFilterItem[filterItem1] && filterItem1 !== '0' ? listFilterItem[filterItem1].label : undefined
    let listFolderName = getListFolderName(listRawData)
    let dataFormat = {}
    let listDataFilter = listRawData
    if (folderName !== undefined) {
      listDataFilter = listRawData.filter((data) => data?.NM_DISP_FLD === folderName)
      if (Number(undownloaded) === 1) {
        listDataFilter = listDataFilter.filter((el) => Number(el.KB_DL) !== Number(undownloaded))
      }

      dataFormat = formatData(listDataFilter, listFolderName, true)
    } else {
      listDataFilter = listRawData

      if (Number(undownloaded) === 1) {
        listDataFilter = listRawData.filter((el) => Number(el.KB_DL) !== Number(undownloaded))
      }

      dataFormat = formatData(listDataFilter, listFolderName, true)
    }

    setFilterItem(filterItem1)
    setListData(dataFormat)
  }

  const handleFilterByUndownloaded = (value) => {
    let listDataFilter = listRawData
    if (Number(value) === 1) {
      listDataFilter = listDataFilter.filter((data) => Number(data.KB_DL) !== Number(value))
    }
    const folderName = !!listFilterItem[filterItem] && filterItem !== '0' ? listFilterItem[filterItem].label : undefined
    if (folderName !== undefined) listDataFilter = listDataFilter.filter((data) => data?.NM_DISP_FLD === folderName)
    let listFolderName = getListFolderName(listRawData)
    const dataFormat = formatData(listDataFilter, listFolderName)
    setListData(dataFormat)
  }

  const listColumnWidth = ['62px', '30%', '8%', '12%', '12%', '12%', '4%', '']
  const listCellHeader = [
    { specialType: 'CHECK_BOX' },
    { columnName: 'dataDownload.lblFileName', commonStyle: 'titleHead', cellStyle: 'titleSpacing' },
    { columnName: 'dataDownload.lblNuSize', commonStyle: 'titleHead', cellStyle: 'titleSpacing' },
    { columnName: 'dataDownload.lblDTDelivery', commonStyle: 'titleHead', cellStyle: 'titleSpacing' },
    { columnName: 'dataDownload.lblDownloadStatus', commonStyle: 'titleHead', cellStyle: 'titleSpacing' },
    { columnName: 'dataDownload.lblDTDownload', commonStyle: 'titleHead', cellStyle: 'titleSpacing' },
    { columnName: '', commonStyle: 'titleHead' },
    { columnName: '', commonStyle: 'titleHead' },
  ]

  const listCellBody = [
    { specialType: 'CHECK_BOX' },
    { keyValue: 'NM_FILE_DSP', cellStyle: 'dataCustUser', gridStyle: 'centerAlignHq' },
    { keyValue: 'NU_SIZE', specialType: 'NU_SIZE' },
    { keyValue: 'DT_REGIST', specialType: 'DT_REGIST' },
    { keyValue: 'KB_DL', specialType: 'KB_DL' },
    { keyValue: 'DT_DL', specialType: 'DT_DL' },
    { specialType: 'PREVIEW' },
    { specialType: 'DOWNLOAD' },
  ]

  const listToolBarComponent = [
    { type: TOOLBAR_TYPE.CHECKBOX, commonStyle: 'checkboxContainer', componentStyle: '' },
    { type: TOOLBAR_TYPE.FILTER_LOGO, commonStyle: 'filterIconContainer', componentStyle: '' },
    { type: TOOLBAR_TYPE.FOLDER_SELECT, commonStyle: 'select-item', componentStyle: '' },
    { type: TOOLBAR_TYPE.SENDING_DATE, commonStyle: 'expireDateContainer', componentStyle: '' },
    { type: TOOLBAR_TYPE.CALENDAR, commonStyle: 'filterContainer', componentStyle: 'calendar-item' },
    { type: TOOLBAR_TYPE.END_CALENDAR, commonStyle: 'filterContainer', componentStyle: 'calendar-item' },
    { type: TOOLBAR_TYPE.CSV, commonStyle: '', componentStyle: '' },
  ]
  let setDownloadParams = {
    screenName: 'data-download',
    folderName: !!listFilterItem[filterItem] && filterItem !== '0' ? listFilterItem[filterItem].label : undefined,
    date: dateFilter,
    undownloaded: undownloaded,
    dayCount: dayCountFilter,
  }
  // useEffect(() => {
  //   setListCheckboxData(listData)
  // }, [selectAll])
  useEffect(() => {
    window.history.replaceState({}, document.title)
    getDataDownload()
  }, [location])

  const getDataDownload = React.useCallback(async () => {
    const { date } = location?.state || {}
    if (date) {
      setDateFilter(date)
      setEndDateFilter(date)
      setIsDateSelected(true)
      dispatch(
        getDataDownloadAction({
          // cdCust: userInfo?.CD_CUST,
          cdCust: userInfo?.CD_CUST,
          date: date,
          dayCount: dayCountFilter,
        })
      )
    } else {
      setDateFilter(DT_TODAY)
      setEndDateFilter(DT_TODAY)
      setIsDateSelected(false)
      dispatch(getDataDownloadAction({ cdCust: userInfo?.CD_CUST }))
    }
  }, [location, dayCountFilter])

  const handleSelectAll = (listData, selectAll) => {
    const list = [...listData]
    const array = list.map((data) => {
      let isSelectedAll = FLAG_BOOLEAN.FALSE
      let listFile = data?.list_file.map((file) => {
        if (!selectAll) {
          isSelectedAll = FLAG_BOOLEAN.TRUE
          return { ...file, IS_SELECTED: FLAG_BOOLEAN.TRUE }
        } else {
          isSelectedAll = FLAG_BOOLEAN.FALSE
          return { ...file, IS_SELECTED: FLAG_BOOLEAN.FALSE }
        }
      })
      return { ...data, IS_SELECTED_ALL: isSelectedAll, list_file: listFile }
    })
    setListCheckboxData(array)
    setListData(array)
    setSelectAll(!selectAll)
    setSelectAllInTable(false)
  }

  const handleSelectAllInTable = (listData, folderName) => {
    const list = [...listData]
    const array = list.map((data) => {
      if (data.NM_DISP_FLD === folderName) {
        if (data.IS_SELECTED_ALL === FLAG_BOOLEAN.FALSE) {
          let listFile = data?.list_file.map((file) => {
            return { ...file, IS_SELECTED: FLAG_BOOLEAN.TRUE }
          })
          return { ...data, IS_SELECTED_ALL: FLAG_BOOLEAN.TRUE, list_file: listFile }
        } else {
          let listFile = data?.list_file.map((file) => {
            return { ...file, IS_SELECTED: FLAG_BOOLEAN.FALSE }
          })
          return { ...data, IS_SELECTED_ALL: FLAG_BOOLEAN.FALSE, list_file: listFile }
        }
      } else {
        return { ...data }
      }
    })
    setListCheckboxData(array)
    setListData(array)
    setSelectAllInTable(!selectAllInTable)
    if (filterItem.toString() === '0') {
      setListCacheData(array)
    }
  }

  useEffect(() => {
    const list = [...listData]
    const array = list.map((data) => {
      let listFile = data?.list_file.filter((file) => file.IS_SELECTED === FLAG_BOOLEAN.TRUE)
      return { ...data, list_file: listFile }
    })
    setListChoosenFolderFile(array)
  }, [listData])

  const updateDataDownload = async (idFile, dtDownDate) => {
    if (userInfo?.CD_CUST && idFile) {
      const kyPart = userInfo?.CD_CUST + idFile
      const dtRegist = dtDownDate
      setCurrentDownloadedFile({ kyPart: kyPart, date: dtRegist })
      dispatch(updateDataDownloadAction({ kyPart: kyPart, date: dtRegist }))
    }
  }
  useEffect(() => {
    handleUpdateAfterDownload(currentDownloadedFile)
  }, [currentDownloadedFile])

  const handleUpdateAfterDownload = (currentDownloadedFile) => {
    let list = []
    listData.forEach((data) => {
      let listFile = [...data?.list_file]
      const fileIndex = data?.list_file.findIndex((file) => {
        return file.KY_PARTS === currentDownloadedFile.kyPart && file.DT_REGIST === currentDownloadedFile.date
      })
      if (fileIndex !== -1) {
        listFile[fileIndex] = {
          ...listFile[fileIndex],
          DT_DL: formatCurrentDateJPWithPadding('yyyy-MM-dd HH:mm:ss.SSS'),
          KB_DL: '1 ',
        }
        list.push({ ...data, list_file: listFile })
      } else {
        list.push({ ...data })
      }
    })
    const listRawDataUpdate = listRawData
    listRawDataUpdate.forEach((data) => {
      if (data.KY_PARTS === currentDownloadedFile.kyPart && data.DT_REGIST === currentDownloadedFile.date) {
        data.DT_DL = formatCurrentDateJPWithPadding('yyyy-MM-dd HH:mm:ss.SSS')
        data.KB_DL = '1'
      }
    })
    setListRawData(listRawDataUpdate)
    setListData(list)
  }

  const toolbar = (
    <ToolBar
      listToolBarComponent={listToolBarComponent}
      handleFilterDate={handleFilterDate}
      handleFilterEndDate={handleFilterEndDate}
      handleSelectFolderName={handleSelectFolderName}
      setSelectedDate={setDateFilter}
      selectedDate={dateFilter}
      setSelectedEndDate={setEndDateFilter}
      selectedEndDate={endDateFilter}
      listData={listData}
      customCheckboxClassName={'checkbox-download'}
      downloadParams={setDownloadParams}
      setIsValidDate={setIsValidDate}
      setIsInDateRange={setIsInDateRange}
      filterLabel={t('dataDownload.lblIdFolder')}
      setFilterItem={setFilterItem}
      filterItem={filterItem}
      listFilterItem={listFilterItem}
      toolbarNoHeight={true}
      toolbarNoHeightPaddingTop={false}
      undownloaded={undownloaded}
      setUndownloaded={setUndownloaded}
      handleFilterByUndownloaded={handleFilterByUndownloaded}
      batchDownload={true}
      chooseAll={true}
      selectAll={selectAll}
      handleSelectAll={handleSelectAll}
      listChoosenFolderFile={listChoosenFolderFile}
      cdCust={userInfo?.CD_CUST}
      updateDataDownload={updateDataDownload}
      setDownloadErrorMessage={setDownloadErrorMessage}
      setNotExistFileInS3Error={setNotExistFileInS3Error}
    />
  )
  const msgDeleteConfirm = recordDelete ? t('headquarter.msgDeleteConfirm', { idUserValue: recordDelete.ID_USER }) : ''

  return (
    <div className="data-download__wrapper">
      {messageError && <MessageErrorDialog message={messageError} showMessageErrorDialog={setMessageError} />}
      {notExistFileInS3Error && (
        <MessageErrorDialog message={notExistFileInS3Error} showMessageErrorDialog={setNotExistFileInS3Error} />
      )}
      {downloadErrorMessage && (
        <MessageErrorDialog message={downloadErrorMessage} showMessageErrorDialog={setDownloadErrorMessage} />
      )}
      {previewErrorMessage && (
        <MessageErrorDialog message={previewErrorMessage} showMessageErrorDialog={setPreviewErrorMessage} />
      )}

      {loading && <ProgressDialog label={t('dataDownload.loading')} />}
      <ModalConfirm title={msgDeleteConfirm} showModal={Boolean(recordDelete)} setShowModal={setRecordDelete} />
      {loading ? (
        <ProgressDialog label={t('dataDownload.loading')} />
      ) : (
        <div className="data-download__container">
          <div className="table-scroll">
            <div>{toolbar}</div>
            <div className="table-list-container">
              {listData &&
                listData
                  .sort((a, b) => a.NM_DISP_FLD.localeCompare(b.NM_DISP_FLD))
                  .map((data, index) => {
                    return index === 0 ? (
                      <div>
                        {/* Table */}
                        <TableDataDownload
                          listColumnWidth={listColumnWidth}
                          listCellHeader={listCellHeader}
                          listCellBody={listCellBody}
                          listDataRender={data?.list_file}
                          hasFirstCell={false}
                          hasHeaderLine={true}
                          isDateSelected={isDateSelected}
                          selectedDate={dateFilter}
                          selectedEndDate={endDateFilter}
                          undownloaded={undownloaded}
                          hasRecordCounter={true}
                          hasStartLineItem={true}
                          startLineItem={data?.NM_DISP_FLD}
                          currentFolder={data}
                          setListData={setListData}
                          listData={listData}
                          cdCust={userInfo?.CD_CUST}
                          handleSelectAllInTable={handleSelectAllInTable}
                          selectAllInTable={selectAllInTable}
                          updateDataDownload={updateDataDownload}
                          setDownloadErrorMessage={setDownloadErrorMessage}
                          setPreviewErrorMessage={setPreviewErrorMessage}
                          key={index}
                          checkDataWithItem={checkDataWithItem}
                          setNotExistFileInS3Error={setNotExistFileInS3Error}
                        />
                        <div className="spacing"></div>
                      </div>
                    ) : (
                      <div>
                        {/* Table */}
                        <TableDataDownload
                          listColumnWidth={listColumnWidth}
                          listCellHeader={listCellHeader}
                          listCellBody={listCellBody}
                          listDataRender={data?.list_file}
                          isDateSelected={isDateSelected}
                          selectedDate={dateFilter}
                          selectedEndDate={endDateFilter}
                          undownloaded={undownloaded}
                          hasFirstCell={false}
                          hasHeaderLine={true}
                          hasRecordCounter={true}
                          hasStartLineItem={true}
                          startLineItem={data?.NM_DISP_FLD}
                          currentFolder={data}
                          listData={listData}
                          setListData={setListData}
                          cdCust={userInfo?.CD_CUST}
                          handleSelectAllInTable={handleSelectAllInTable}
                          selectAllInTable={selectAllInTable}
                          updateDataDownload={updateDataDownload}
                          setDownloadErrorMessage={setDownloadErrorMessage}
                          setPreviewErrorMessage={setPreviewErrorMessage}
                          key={index}
                          checkDataWithItem={checkDataWithItem}
                          setNotExistFileInS3Error={setNotExistFileInS3Error}
                        />
                        <div className="spacing"></div>
                      </div>
                    )
                  })}

              {listData && listData.length === 0 && (
                <TableDataDownload
                  // handleDelete={setRecordDelete}
                  hasFirstCell={false}
                  hasHeaderLine={false}
                  toolbar={toolbar}
                  isDateSelected={isDateSelected}
                  selectedDate={dateFilter}
                  selectedEndDate={endDateFilter}
                  hasRecordCounter={false}
                  hasStartLineItem={false}
                  checkDataWithItem={checkDataWithItem}
                  setNotExistFileInS3Error={setNotExistFileInS3Error}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default DataDownload
