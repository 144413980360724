export const GET_COMPLIANCE_LIST_REQUEST = 'GET_COMPLIANCE_LIST_REQUEST'
export const GET_COMPLIANCE_LIST_SUCCESS = 'GET_COMPLIANCE_LIST_SUCCESS'
export const GET_COMPLIANCE_LIST_FAILED = 'GET_COMPLIANCE_LIST_FAILED'

export const GET_COMPLIANCE_RETRY_LIST_REQUEST = 'GET_COMPLIANCE_RETRY_LIST_REQUEST'
export const GET_COMPLIANCE_RETRY_LIST_SUCCESS = 'GET_COMPLIANCE_RETRY_LIST_SUCCESS'
export const GET_COMPLIANCE_RETRY_LIST_FAILED = 'GET_COMPLIANCE_RETRY_LIST_FAILED'

export const UPDATE_COMPLIANCE_REQUEST = 'UPDATE_COMPLIANCE_REQUEST'
export const UPDATE_COMPLIANCE_SUCCESS = 'UPDATE_COMPLIANCE_SUCCESS'
export const UPDATE_COMPLIANCE_FAILED = 'UPDATE_COMPLIANCE_FAILED'

export const CLEAR_COMPLIANCE = 'CLEAR_COMPLIANCE'
